import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { nautilusTritonTestnetTokens } from './91002'
import { nautilusProteusTestnetTokens } from "./88002";

export const CAKE_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const USDC_BSC = new ERC20Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_NAUTILUS_TRITON = new ERC20Token(
  ChainId.NAUTILUS_TRITON_TESTNET,
  '0x8Cdf221CF3B4C875fFf43Df6b9B4dEDd54691A39',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_NAUTILUS_PROTEUS = new ERC20Token(
  ChainId.NAUTILUS_PROTEUS_TESTNET,
  '0x5c3ce30Cb0eFC3540A8013f91CD1288428B78689',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const ZBC_BSC_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0x37a56cdcD83Dce2868f721De58cB3830C44C6303',
  9,
  'ZBC',
  'ZEBEC',
  'https://zebec.io/',
)

export const WZBC_NAUTILUS_TRITON_TESTNET = new ERC20Token(
  ChainId.NAUTILUS_TRITON_TESTNET,
  '0xA178E22ae6d720a4fDA619AeC392F95C5bC7cAeD',
  18,
  'WZBC',
  'Wrapped ZBC',
  'https://poseiswap.xyz',
)

export const WZBC_NAUTILUS_PROTEUS_TESTNET = new ERC20Token(
  ChainId.NAUTILUS_PROTEUS_TESTNET,
  '0xB9AEFBF3a09fab81656CE36B61Aac8E00d12d947',
  18,
  'WZBC',
  'Wrapped ZBC',
  'https://poseiswap.xyz',
)

export const POSE_BSC_MAINNET = new ERC20Token(
  ChainId.BSC,
  '0xd7518E8cFd7448201155BbBEDEed88888E3575aE',
  18,
  'POSE',
  'POSE',
  'https://www.poseiswap.xyz/',
)


export const BUSD = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
  [ChainId.NAUTILUS_TRITON_TESTNET]: BUSD_NAUTILUS_TRITON,
  [ChainId.NAUTILUS_PROTEUS_TESTNET]: BUSD_NAUTILUS_PROTEUS,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.NAUTILUS_TRITON_TESTNET]: nautilusTritonTestnetTokens.usdc,
  [ChainId.NAUTILUS_PROTEUS_TESTNET]: nautilusProteusTestnetTokens.usdc,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
  [ChainId.NAUTILUS_TRITON_TESTNET]: nautilusTritonTestnetTokens.usdt,
  [ChainId.NAUTILUS_PROTEUS_TESTNET]: nautilusProteusTestnetTokens.usdt,
}

export const ZBC = {
  [ChainId.BSC]: ZBC_BSC_MAINNET,
  [ChainId.NAUTILUS_TRITON_TESTNET]: WZBC_NAUTILUS_TRITON_TESTNET,
  [ChainId.NAUTILUS_PROTEUS_TESTNET]: WZBC_NAUTILUS_PROTEUS_TESTNET,
}

export const WBTC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC',
)

export const POSE = {
  [ChainId.NAUTILUS_TRITON_TESTNET]: WZBC_NAUTILUS_TRITON_TESTNET,
  [ChainId.NAUTILUS_PROTEUS_TESTNET]: WZBC_NAUTILUS_PROTEUS_TESTNET,
  [ChainId.BSC]: POSE_BSC_MAINNET,

}