import { ChainId } from '@pancakeswap/sdk'
import memoize from 'lodash/memoize'
import invert from 'lodash/invert'

export const CHAIN_QUERY_NAME = {
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bscTestnet',
  [ChainId.NAUTILUS_TRITON_TESTNET]: 'triton',
  [ChainId.NAUTILUS_PROTEUS_TESTNET]: 'proteus',
} satisfies Record<ChainId, string>

const CHAIN_QUERY_NAME_TO_ID = invert(CHAIN_QUERY_NAME)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName] ? +CHAIN_QUERY_NAME_TO_ID[chainName] : undefined
})

export const NAUTILUS_TRITON_CHAIN_INFO = {
  chainId: '0x1637a',
  chainIdinDecimal: 91002,
  chainName: 'Triton Testnet',
  network: 'Triton',
  nativeCurrency: {
    decimals: 18,
    name: 'Zebec',
    symbol: 'ZBC',
  },
  rpcUrls: ['https://triton.api.nautchain.xyz'],
  blockExplorerUrls: ['https://triton.nautscan.com/'],
  blockExplorerName: 'Nautilus Explorer',
}

export const NAUTILUS_PROTEUS_CHAIN_INFO = {
  chainId: '0x157c2',
  chainIdinDecimal: 88002,
  chainName: 'Proteus Testnet',
  network: 'Proteus',
  nativeCurrency: {
    decimals: 18,
    name: 'Zebec',
    symbol: 'ZBC',
  },
  rpcUrls: ['https://api.proteus.nautchain.xyz/solana'],
  blockExplorerUrls: ['https://proteus.nautscan.com'],
  blockExplorerName: 'Nautilus Explorer',
}
