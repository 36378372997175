import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapMenuIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  IfoIcon,
  TrophyIcon,
  TrophyFillIcon,
  //   NftIcon,
  //   NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
// import { getPerpetualUrl } from 'utils/getPerpetualUrl'
import { SUPPORT_ONLY_BSC, SUPPORT_ONLY_NAUTILUS } from 'config/constants/supportChains'
import { ChainId } from '@pancakeswap/sdk'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapMenuIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        //         {
        //           label: t('Limit'),
        //           href: '/limit-orders',
        //           supportChainIds: SUPPORT_ONLY_BSC,
        //           image: '/images/decorations/3d-coin.png',
        //         },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        //         {
        //           label: t('Perpetual'),
        //           href: getPerpetualUrl({
        //             chainId,
        //             languageCode,
        //             isDark,
        //           }),
        //           type: DropdownMenuItemType.EXTERNAL_LINK,
        //         },
        //         {
        //           label: t('Bridge'),
        //           href: 'https://bridge.pancakeswap.finance/',
        //           type: DropdownMenuItemType.EXTERNAL_LINK,
        //         },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    // label: t('Bridge (BETA)'),
    // href: '/farms',
    // icon: EarnIcon,
    // fillIcon: EarnFillIcon,
    // items: [
    //   {
    //     label: t('Goeril -> Triton, USDC only'),
    //     href: 'https://test.multichain.org/#/router',
    //     target: '_blank',
    //   },
    // ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    {
      label: t('Faucet'),
      href:
        ChainId.NAUTILUS_PROTEUS_TESTNET === chainId
          ? 'https://proteusfaucet.nautchain.xyz/'
          : 'https://faucet.nautchain.xyz/',
      supportChainIds: SUPPORT_ONLY_NAUTILUS,
      target: '_blank',
      items: [],
    },
    {
      label: t('ZBC Staking'),
      href: 'https://www.zbcstaking.com/',
      showItemsOnMobile: false,
      supportChainIds: SUPPORT_ONLY_NAUTILUS,
      target: '_blank',
      items: [],
    },
    {
      label: t('Staking'),
      href: 'https://posestaking.poseiswap.xyz/',
      showItemsOnMobile: false,
      supportChainIds: SUPPORT_ONLY_BSC,
      items: [],
    },
    {
      label: t('LSD'),
      href: 'https://lsd.poseiswap.xyz/',
      supportChainIds: SUPPORT_ONLY_BSC,
      showItemsOnMobile: false,
      target: '_blank',
      items: [],
    },
    // {
    //   label: t('Earn'),
    //   href: '/farms',
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   items: [
    //     {
    //       label: t('Farms'),
    //       href: '/farms',
    //     },
    //     {
    //       label: t('Pools'),
    //       href: '/pools',
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Win'),
    //   href: '/prediction',
    //   icon: TrophyIcon,
    //   fillIcon: TrophyFillIcon,
    //   supportChainIds: SUPPORT_ONLY_NAUTILUS,
    //   items: [
    //     {
    //       label: t('Trading Competition'),
    //       href: '/competition',
    //       image: '/images/decorations/tc.png',
    //       hideSubNav: true,
    //     },
    //     // {
    //     //   label: t('Prediction (BETA)'),
    //     //   href: '/prediction',
    //     //   image: '/images/decorations/prediction.png',
    //     // },
    //     // {
    //     //   label: t('Lottery'),
    //     //   href: '/lottery',
    //     //   image: '/images/decorations/lottery.png',
    //     // },
    //     // {
    //     //   label: t('Pottery (BETA)'),
    //     //   href: '/pottery',
    //     //   image: '/images/decorations/lottery.png',
    //     // },
    //   ],
    // },
    //     {
    //       label: t('NFT'),
    //       href: `${nftsBaseUrl}`,
    //       icon: NftIcon,
    //       fillIcon: NftFillIcon,
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //       image: '/images/decorations/nft.png',
    //       items: [
    //         {
    //           label: t('Overview'),
    //           href: `${nftsBaseUrl}`,
    //         },
    //         {
    //           label: t('Collections'),
    //           href: `${nftsBaseUrl}/collections`,
    //         },
    //         {
    //           label: t('Activity'),
    //           href: `${nftsBaseUrl}/activity`,
    //         },
    //       ],
    //     },
    // {
    //   label: t('IFO'),
    //   href: '/ifo',
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   icon: IfoIcon,
    //   fillIcon: IfoIcon,
    //   items: [],
    // },
    // {
    //   label: t('Docs'),
    //   href: 'https://docs.poseiswap.xyz',
    //   type: DropdownMenuItemType.EXTERNAL_LINK,
    //   items: []
    // },
    // {
    //   label: '',
    //   href: '/info',
    //   icon: MoreIcon,
    //   hideSubNav: true,
    //   items: [
    //         {
    //           label: t('Info'),
    //           href: '/info',
    //         },
    //         {
    //           label: t('Voting'),
    //           href: '/voting',
    //           supportChainIds: SUPPORT_ONLY_BSC,
    //           image: '/images/voting/voting-bunny.png',
    //         },
    //         {
    //           type: DropdownMenuItemType.DIVIDER,
    //         },
    //         {
    //           label: t('Leaderboard'),
    //           href: '/teams',
    //           supportChainIds: SUPPORT_ONLY_BSC,
    //           image: '/images/decorations/leaderboard.png',
    //         },
    //         {
    //           type: DropdownMenuItemType.DIVIDER,
    //         },
    //         {
    //           label: t('Blog'),
    //           href: 'https://medium.com/pancakeswap',
    //           type: DropdownMenuItemType.EXTERNAL_LINK,
    //         },
    // ].map((item) => addMenuItemSupported(item, chainId)),
    // },

  ].map((item) => addMenuItemSupported(item, chainId))

export default config
