import { ChainId, ERC20Token, WZBC } from '@pancakeswap/sdk'

export const nautilusProteusTestnetTokens = {
  wzbc: WZBC[ChainId.NAUTILUS_PROTEUS_TESTNET],
  btc: new ERC20Token(ChainId.NAUTILUS_PROTEUS_TESTNET, '0x5bb71B23BE64304cec861A0351874f7EFF50a438', 18, 'BTC', 'BTC'),
  eth: new ERC20Token(ChainId.NAUTILUS_PROTEUS_TESTNET, '0xDe157814Fe8b4F5396BaAc088b8623A687B7BC63', 18, 'ETH', 'ETH'),
  usdt: new ERC20Token(ChainId.NAUTILUS_PROTEUS_TESTNET, '0xa713805Aa4185503E1A741EeAe2f4D6478c22818', 18, 'USDT', 'USDT'),
  usdc: new ERC20Token(ChainId.NAUTILUS_PROTEUS_TESTNET, '0xFAAD3bD0B671924ea4376C90C06e575AF01668D8', 18, 'USDC', 'USDC'),
  dai: new ERC20Token(ChainId.NAUTILUS_PROTEUS_TESTNET, '0x7cf1125ad056D96F78b596a1D6BC51b8Ad8767Bb', 18, 'DAI', 'DAI'),
}
